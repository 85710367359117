.privacy {
    width: 70%;
    margin: 5% auto 8% auto;
}

.privacy>p{
    font-family: 'Jost';
    color: #4D5053;
}

.privacyTitle {
    font-weight: bold;
}