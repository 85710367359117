
.other-member-photo {
  width: 100%;
  object-fit: cover;
  margin: 0% 3% 0% 0%;
  border-radius: 30px;
}

.other-member-photo > img {
  width: 100%;
}
