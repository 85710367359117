.services {
    width: 100%;
}

.services-header {
  width: 100%;
  min-height: 43vh;
  background-image: url("../images/backgroung/servicesBg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
}

.services-header > h1 {
  width: 20%;
  margin: 0;
  padding: 2% 3% 0.5% 3%;
  background-color: white;
  color: #292f36;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.services-header > h1 > p {
  color: #4d5053;
  font-size: 14px;
  font-family: Jost;
}

/* SERVICE TYPES */
.service-types {
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    margin: 5% auto;
}

.service-type {
    width: 100%;
    display: flex;
    flex: 1 1 200px;
    flex-direction: column;
    justify-content: center;
    padding: 5% 3%;
    text-align: center;
    align-items: center;
    border-radius: 20px;
}

.selected-type {
    background-color: #F4F0EC;
}

.service-type>h2 {
    font-family: 'Dm Serif Display';
    margin: 0;
    color: #292f36;
}

.service-type>p {
    font-family: 'Jost';
    color: #4d5053;
}

.service-type>a{
    width: 50%;
}

.service-type>a>button{
    background-color: transparent;
    border: none;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Jost';
    font-size: 14px;
    font-weight: 600;
    color: #292f36;
}

/* HOW WE WORK */
.howWeWork {
    color: #292f36;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 3% auto;
    background-color: #F4F0EC;
    border-radius: 30px;
    padding: 7% 5%;
}

.how-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 7% 0% 3% 0%;
}

.hc-img {
    width: 50%;
    object-fit: cover;
}

.hc-img>img {
    width: 100%;
    border-top-right-radius: 25%;
    border-bottom-left-radius: 70%;
}

.sc-img {
    width: 70%;
    object-fit: cover;
}

.sc-img>img {
    width: 100%;
    border-radius: 30px;
}


.how-title {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 90%;
}

.how-title>h1 {
    margin: 0;
}

.how-title>p {
    font-family: 'Jost';
    color: #4d5053;
}

.hc-text {
    margin: 0% 5%;
    width: 45%;
}

.hc-text-anima {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 5% 0;
}

.hc-text-anima>img {
    width: 25%;
}

.hc-text-anima>p {
    font-size: 10vh;
    font-family: 'Dm Serif Display';
    margin: 0;
    color: white;
}

.hc-subtext>p {
    color: #4d5053;
    font-size: 14px;
}

/* INTERNO */
.vikash {
    width: 60%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 10% auto;
    padding: 3% 0;
    background-color: #292F36;
    border-radius: 50px;
    color: white;
}

.vikash>h1 {
    margin: 0;
    color: white;
}

.vikash>a {
    width: 22%;
    margin: 3% 0%;
}

.vikash>a>button {
    color: white;
    width: 100%;
    padding: 8% 0%;
    border-radius: 15px;
    border: none;
    background-color: #CDA274;
    display: flex;
    justify-content: center;
    align-items: center;
}